import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ITkProductModel} from "../../models/product";
import slugify from "slugify";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkConfirmBackorderModal from "../../components/particles/TkConfirmBackorderModal";
import "./style.scss";
import {
  useTkAppErrors,
  useTkCart,
  useTkMedia,
  useTkProduct,
  useTkRecommender,
  useTkShipment,
  useTkUser,
} from "../../context/TkContext";
import {tk_route_item_added_to_cart} from "../TkItemAddedToCartView";
import LargeScreen from "./_largeScreen";
import MediumScreen from "./_mediumScreen";
import SmallScreen from "./_smallScreen";
import {vibrate} from "../../utils/utils";
import {transformGraphQLErrors} from "../../models/graphql";
import TkModal, {TkModalActions, TkModalBody,} from "../../components/particles/TkModal";
import {TkEngagementFunnelEntryType} from "../../models/engagementFunnel";
import TkConfirmHDRXModel from "../../components/particles/TkConfirmHDRXModal";

export const tk_route_product_detail = (product?: ITkProductModel) => {
  if (product) {
    const url = [`/${product._id}/${slugify(product.name).toLowerCase()}`]
    if (product.isCameFromRecommender) {
      const p = new URLSearchParams()
      p.set('isCameFromRecommender', 'true')
      url.push('?', p.toString())
    }

    return url.join('');
  }
  return "/:id/:name";
};

const TkProductDetailView: React.FC = () => {
  const {id} = useParams();
  const [loadingProduct, setLoadingProduct] = useState<boolean>(true);
  const [product, setProduct] = useState<ITkProductModel | null | undefined>(null);
  const [showAlertValidation, setShowAlertValidation] = useState<string>(null);
  const [showConfirmBackorderModal, setShowConfirmBackorderModal] = useState<boolean>(false);
  const [showConfirmHdrXModal, setShowConfirmHdrXModal] = useState<boolean>(false);

  const {isMediaXs, isMediaSm} = useTkMedia();
  const {goToPageError} = useTkAppErrors();
  const {getProductDetails} = useTkProduct();
  const {registerEngagement, isCameFromRecommender} = useTkRecommender()
  const {isWithdraw, isRSShipment} = useTkShipment()
  const {userAuth, isAuth, me} = useTkUser();


  const [quantity, setQuantity] = useState(1);
  const {addToCart} = useTkCart();
  const history = useHistory();

  const add = useCallback(async () => {
    if (product) {
      const isPartialBackorder =
        product.leadTime && product.stock && quantity > product.stock;
      const isLimitedDelivery = product.isRestrictedDelivery && !isRSShipment
      if(!showConfirmHdrXModal && !isWithdraw && isLimitedDelivery){
          setShowConfirmHdrXModal(true)
      }else if (!showConfirmBackorderModal && isPartialBackorder) {
        setShowConfirmBackorderModal(true);
      } else {
        setShowConfirmBackorderModal(false);
        setShowConfirmHdrXModal(false)
        try {
          await addToCart(product, quantity, !isLimitedDelivery ? isWithdraw : true);
          history.push(tk_route_item_added_to_cart(product));
        } catch (e) {
          vibrate();
          const msgs = transformGraphQLErrors(e);
          setShowAlertValidation(
            msgs
              ? msgs.map((m) => m.message).join("\n")
              : "Falhou ao tentar adicionar ao carrinho. Tente novamente."
          );
        }
      }
    }
  }, [quantity, product, isWithdraw, showConfirmBackorderModal, showConfirmHdrXModal, isCameFromRecommender, isRSShipment]);

  useEffect(() => {
    if (isAuth() && !userAuth.user.defaultAddress) {
      me()
    }
  }, []);

  useEffect(() => {
    if (id) {
      setLoadingProduct(true);

      getProductDetails(id)
        .then(p => {
          setProduct({...p, isCameFromRecommender: isCameFromRecommender()})
          if(id != p._id){
            history.replace({pathname: tk_route_product_detail(p)})
          }
        })
        .catch(goToPageError)
        .finally(() => {
          setLoadingProduct(false);
          window.scrollTo(0, 0);

          if (isCameFromRecommender()) registerEngagement(TkEngagementFunnelEntryType.click, id)
        });
    }
  }, [id, setLoadingProduct, setProduct]);

  useEffect(() => {
    if (product) {
      if (product.minimumSaleQuantity > 0 && product.controlMultiplicity) {
        setQuantity(product.minimumSaleQuantity);
      }
    }
  }, [product]);

  let bestScreen;

  if (!isMediaXs && !isMediaSm) {
    bestScreen = (
      <LargeScreen
        add={add}
        loadingProduct={loadingProduct}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    );
  } else if (isMediaXs) {
    bestScreen = (
      <SmallScreen
        add={add}
        loadingProduct={loadingProduct}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    );
  } else {
    bestScreen = (
      <MediumScreen
        add={add}
        loadingProduct={loadingProduct}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    );
  }

  return <>
    {!loadingProduct && product && (
      <TkHeadSEO
        title={`${product.name} | Teky`}
        url={document.location.href}
        product={product}
      />
    )}
    <TkHeader/>
    <TkDivisionsTop/>
    <div key={product?._id}>{bestScreen}</div>
    <TkNewsLetter/>
    <TkFooter/>
    {showConfirmBackorderModal && (
        <TkConfirmBackorderModal
          product={product}
          show={showConfirmBackorderModal === true}
          close={() => setShowConfirmBackorderModal(false)}
          onConfirm={add}
          quantity={quantity}
        />
      )}
    {showConfirmHdrXModal && (
        <TkConfirmHDRXModel
          product={product}
          show={showConfirmHdrXModal}
          close={() => setShowConfirmHdrXModal(false)}
          onConfirm={add}
        />
    )}
    <TkModal
      show={showAlertValidation !== null}
      close={() => setShowAlertValidation(null)}
      title="Adicionar ao Carrinho"
      size="sm"
    >
      <TkModalBody>{showAlertValidation}</TkModalBody>
      <TkModalActions>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setShowAlertValidation(null)}
        >
          Fechar
        </button>
      </TkModalActions>
    </TkModal>
  </>
};

export default TkProductDetailView;
